import React, { useEffect, useState, useRef } from 'react';
import { Table, Modal, OverlayTrigger, Tooltip, Button, Pagination, Form, InputGroup, FormControl, Col, Row } from 'react-bootstrap';
import ContactList from './ContactList';
import CustomerForm from './CustomerForm';
import { db } from '../firebaseConfig'; // Import Firebase config
import { collection, onSnapshot } from 'firebase/firestore';
import { ChevronDown, ChevronUp, ArrowDownUp, InfoCircle, Trash, Wrench, PlusCircle, PlusCircleFill, Search } from "react-bootstrap-icons";

function CustomerList({ search }) {
  const [customers, setCustomers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [customerToDelete, setCustomerToDelete] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [customerToEdit, setCustomerToEdit] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [customerToShow, setCustomerToShow] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState(search);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const [localCustomers, setLocalCustomers] = useState([]);
  const cleanupRef = useRef(null);
  const [currentRows, setCurrentRows] = useState([]);

  // Fetching customers and contacts from Firestore
  useEffect(() => {
    const unsubscribeCustomers = onSnapshot(collection(db, 'customers'), (snapshot) => {
      const customerData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCustomers(customerData);
    });

    const unsubscribeContacts = onSnapshot(collection(db, 'customerContacts'), (snapshot) => {
      const contactData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setContacts(contactData);
    });

    // Clean up listeners on component unmount
    return () => {
      unsubscribeCustomers();
      unsubscribeContacts();
    };
  }, []);

  useEffect(() => {
    if (customerToShow) {
      const updatedCustomer = customers.find(cust => cust.strCustNbr === customerToShow.strCustNbr);
      if (updatedCustomer) {
        setCustomerToShow(updatedCustomer); // Update customerToShow if the data is changed
      }
    }
  }, [customers, customerToShow]);


  // Filter customers based on search query
  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filteredCustomers = customers.filter(customer => {
      return (
        customer.strCustNbr?.toLowerCase().includes(lowercasedQuery) ||
        customer.strCustName?.toLowerCase().includes(lowercasedQuery) ||
        customer.lstrCustNotes?.toLowerCase().includes(lowercasedQuery) ||
        customer.strCDKCustNbrFord?.toLowerCase().includes(lowercasedQuery) ||
        customer.strCDKCustNbrGM?.toLowerCase().includes(lowercasedQuery)
      );
    });
    setLocalCustomers(filteredCustomers);  // Always keep localCustomers updated
    setTotalRows(filteredCustomers.length);
    setCurrentPage(1); // Reset to the first page when filtering changes
  }, [customers, searchQuery]);



  const getContactListForCustomer = (custNbr) => {
    const customerContacts = contacts.filter(contact => contact.strCustNbr === custNbr);
    return customerContacts;
  };

  const handleCustomerRowClick = (customer) => {
    if (selectedCustomer && selectedCustomer.strCustNbr === customer.strCustNbr) {
      setSelectedCustomer(null); // Deselect if the same customer is clicked again
    } else {
      setSelectedCustomer(customer);
      setCustomerToShow(customer); // Ensure customerToShow is updated here
    }
  };


  // Function to handle customer deletion
  const handleCustomerDeleted = async (customer) => {
    const updatedCustomers = customers.filter(cust => cust.id !== customer.id);
    setCustomers(updatedCustomers);
    setShowDeleteModal(false);
  };

  const handleCustomerUpdated = async (updatedCustomer) => {

    // If customerToShow is the updated customer, update customerToShow too
    if (customerToShow && customerToShow.id === updatedCustomer.id) {
      setCustomerToShow(updatedCustomer);
    }

    setShowEditModal(false);  // Optionally close the modal after the update
  };



  const handleCustomerAdded = async () => {
    // Customer data will be automatically updated via the Firestore `onSnapshot`
    setShowAddModal(false);
  };

  const handleContactAdded = async () => {
    // Contact data will be automatically updated via the Firestore `onSnapshot`
  };

  const handleCloseModal = () => {
    if (cleanupRef.current) {
      cleanupRef.current();
    }
    setShowAddModal(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSortClick = (column) => {
    let direction = 'asc';
    if (sortColumn === column && sortDirection === 'asc') {
      direction = 'desc';
    } else if (sortColumn === column && sortDirection === 'desc') {
      direction = 'asc';
    }
    setSortColumn(column);
    setSortDirection(direction);

    const sortedCustomers = [...localCustomers].sort((a, b) => {
      let fieldA = a[column]?.toString().toLowerCase() || '';
      let fieldB = b[column]?.toString().toLowerCase() || '';
      return direction === 'asc' ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
    });
    setLocalCustomers(sortedCustomers);
  };

  // Combine address fields into a single formatted string
  const getFormattedAddress = (customer) => {
    const addressLine1 = customer.strAddressLine1 || '';
    const addressLine2 = customer.strAddressLine2 ? `, ${customer.strAddressLine2}` : '';
    const city = customer.strCity ? `, ${customer.strCity}` : '';
    const state = customer.strState ? `, ${customer.strState}` : '';
    const zip = customer.strZip ? `, ${customer.strZip}` : '';
    return `${addressLine1}${addressLine2}${city}${state}${zip}`.trim();
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;

  useEffect(() => {
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const updatedRows = localCustomers.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(updatedRows);
  }, [localCustomers, currentPage, rowsPerPage]);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(localCustomers.length / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search Customers"
          aria-label="Search Customers"
          aria-describedby="search-addon"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </InputGroup>
      <Table hover>
        <thead>
          <tr>
            <th>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Add Customer</Tooltip>}
              >
                <Button
                  onClick={() => setShowAddModal(true)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="icon-hover me-3"
                  variant="link"
                >
                  {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                </Button>
              </OverlayTrigger>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Customer #</span>
                <Button variant='white' onClick={() => handleSortClick('strCustNbr')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>Name</span>
                <Button variant='white' onClick={() => handleSortClick('strCustName')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>CDK Store</span>
                <Button variant='white' onClick={() => handleSortClick('nCDKStore')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>CDK Customer # (FORD)</span>
                <Button variant='white' onClick={() => handleSortClick('strCDKCustNbrFord')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th>
              <div className="d-flex justify-content-between align-items-center">
                <span>CDK Customer # (GM)</span>
                <Button variant='white' onClick={() => handleSortClick('strCDKCustNbrGM')}>
                  <ArrowDownUp />
                </Button>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {currentRows.map(customer => (
            <React.Fragment key={customer.strCustNbr}>
              <tr onClick={() => handleCustomerRowClick(customer)}>
                <td>{selectedCustomer && selectedCustomer.strCustNbr === customer.strCustNbr ? <ChevronUp /> : <ChevronDown />}</td>
                <td>{customer.strCustNbr}</td>
                <td>{customer.strCustName}</td>
                <td>{customer.nCDKStore}</td>
                <td>{customer.strCDKCustNbrFord}</td>
                <td>{customer.strCDKCustNbrGM}</td>
                <td>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Detail</Tooltip>}>
                    <Button
                      onClick={() => { setCustomerToShow(customer); setShowDetailModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <InfoCircle color="blue" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}>
                    <Button
                      onClick={() => { setCustomerToEdit(customer); setShowEditModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Wrench color="black" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
                    <Button
                      onClick={() => { setCustomerToDelete(customer); setShowDeleteModal(true); }}
                      variant="white"
                      style={{ padding: '2px 4px' }}
                    >
                      <Trash color="red" />
                    </Button>
                  </OverlayTrigger>
                </td>
              </tr>
              {selectedCustomer && selectedCustomer.strCustNbr === customer.strCustNbr && (
                <tr>
                  <td colSpan="7">
                    <ContactList
                      contacts={getContactListForCustomer(customer.strCustNbr)}
                      customerNumber={customer.strCustNbr}
                      upfitterNumber=""
                      onContactAdded={handleContactAdded}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>

      <div className="d-flex justify-content-between align-items-center my-3 mb-5" style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this customer?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={() => handleCustomerDeleted(customerToDelete)}>Delete</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerForm
            customer={customerToEdit}
            onCustomerUpdated={(updatedCustomer) => {
              handleCustomerUpdated(updatedCustomer);
              setShowEditModal(false);
            }}
          />
        </Modal.Body>
      </Modal>

      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Customer Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {customerToShow && (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              <Row>
                <Col><strong>Customer Number:</strong></Col>
                <Col>{customerToShow.strCustNbr}</Col>
              </Row>
              <Row>
                <Col><strong>Name:</strong></Col>
                <Col>{customerToShow.strCustName}</Col>
              </Row>
              <Row>
                <Col><strong>CDK Store:</strong></Col>
                <Col>{customerToShow.nCDKStore}</Col>
              </Row>
              <Row>
                <Col><strong>CDK Customer Number (FORD):</strong></Col>
                <Col>{customerToShow.strCDKCustNbrFord}</Col>
              </Row>
              <Row>
                <Col><strong>CDK Customer Number (GM):</strong></Col>
                <Col>{customerToShow.strCDKCustNbrGM}</Col>
              </Row>
              <Row>
                <Col><strong>Address:</strong></Col>
                <Col>{getFormattedAddress(customerToShow)}</Col>
              </Row>
              <Row>
                <Col><strong>Notes:</strong></Col>
                <Col>{customerToShow.lstrCustNotes}</Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>

      <Modal show={showAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomerForm
            onCustomerAdded={handleCustomerAdded}
            onModalClose={handleCloseModal}
            registerCleanup={(cleanup) => (cleanupRef.current = cleanup)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CustomerList;
