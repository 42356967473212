import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, Button, Modal, OverlayTrigger, Tooltip, FormControl, InputGroup, Form, Pagination, ProgressBar, Spinner, Row, Col } from 'react-bootstrap';
import VehicleForm from './VehicleForm';
import { CircleFill, PlusCircle, PlusCircleFill, InfoCircle, Trash, Wrench, ArrowBarUp, Search, MicrosoftTeams, ArrowDownUp } from "react-bootstrap-icons";
import { read, utils } from 'xlsx';
import { useFirestore } from '../contexts/FirestoreContext';
//import { createTeamsFoldersForVehicles } from '../utils/teamsUtils';
import { Timestamp } from 'firebase/firestore';
import Papa from 'papaparse';


function VehicleList({ selectedOrder, addButtonActive, allVehicles = false, rowPages = 10 }) {
  const [vehicles, setVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState([]);
  const [vehicleToShow, setVehicleToShow] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showBulkEditModal, setShowBulkEditModal] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(rowPages);
  const [totalRows, setTotalRows] = useState(0);
  const [showTeamsModal, setShowTeamsModal] = useState(false);
  const [teamsProgress, setTeamsProgress] = useState(0);
  const [teamsLoading, setTeamsLoading] = useState(false);
  const { getData, queryData, updateData, addData, deleteData, getDocument, onSnapshotQueryData, onSnapshotData } = useFirestore();
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [reload, setReload] = useState(0);
  const [eventCodes, setEventCodes] = useState({});
  const [modelCodes, setModelCodes] = useState({});
  const [colorCodes, setColorCodes] = useState({});
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc'); // Default direction is 'asc'
  const [salesOrders, setSalesOrders] = useState([]); // Store Sales Orders
  const [customers, setCustomers] = useState([]); // Store Customers
  const [currentVehicleInfo, setCurrentVehicleInfo] = useState(null); // Track current vehicle info
  const [currentVehicleStatus, setCurrentVehicleStatus] = useState(''); // Track if the vehicle is New or Updated


  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = () => {
      const vehicleUnsubscribe = selectedOrder
        ? onSnapshotQueryData('vehicles', 'strSalesOrdNbr', '==', selectedOrder.strSalesOrdNbr, (vehicleData) => {
          setVehicles(vehicleData);
        })
        : onSnapshotData('vehicles', (vehicleData) => {
          setVehicles(vehicleData);
        });

      const salesOrderUnsubscribe = onSnapshotData('salesOrders', (salesOrderData) => {
        setSalesOrders(salesOrderData);
      });

      const customerUnsubscribe = onSnapshotData('customers', (customerData) => {
        setCustomers(customerData);
      });

      // Clean up the listeners on component unmount
      return () => {
        vehicleUnsubscribe();
        salesOrderUnsubscribe();
        customerUnsubscribe();
      };
    };

    fetchData();
  }, [onSnapshotData, onSnapshotQueryData, selectedOrder]);


  const handleSalesOrderClick = (soNumber) => {
    navigate(`/salesorders?search=${soNumber}`);
  }

  const calculateGreenPercentage = () => {
    const greenVehicles = filteredVehicles.filter(vehicle => {
      const statusColor = getStatusColor(vehicle);
      return statusColor === 'green'; // Bootstrap's green color
    });

    const percentage = filteredVehicles.length > 0 ? (greenVehicles.length / filteredVehicles.length) * 100 : 0;
    return Math.round(percentage); // Return the rounded percentage
  };

  const getStatusColor = (vehicle) => {
    // 5. Delivered to Customer (Green)
    if (vehicle.manualStatus === 'Complete') {
      return 'green'; // Final state, manually set
    }
    if (vehicle.manualStatus === 'In Process') {
      return 'brown';
    }
    if (vehicle.manualStatus === 'Scheduled') {
      return 'brown';
    }
    if (vehicle.manualStatus === 'Produced') {
      return 'brown';
    }
    if (vehicle.manualStatus === 'Delivered') {
      return 'green';
    }

    // 4. Process of Getting Vehicle Ready for Customer Delivery
    if (vehicle.dtCustSignedDate) {
      return 'blue'; // Customer Signed and Ownership Changed
    }

    if (vehicle.strUpfitterStatus === 'Complete') {
      return 'blue'; // At Upfitter (Complete)
    }

    if (vehicle.strUpfitterStatus || vehicle.dtUpfitterETA) {
      return 'blue'; // At Upfitter
    }

    // 3. Ready for Customer Signature
    if (vehicle.strVinStatus === 'Delivered') {
      return 'orange'; // On the Ground (Delivered at dealership or upfitter)
    }

    if (vehicle.dtPDI) {
      return 'orange'; // PDI (Pre-Delivery Inspection)
    }

    // 2. Vehicle In Process (Check for sub-statuses in reverse order)
    if (vehicle.dtManETA) {
      return 'brown'; // In Transit to Destination
    }

    if ((vehicle.strManStat >= 3800 && vehicle.strFactory === 'GM') ||
      (vehicle.strManStat === 'Produced' && vehicle.strFactory === 'Ford')) {
      return 'brown'; // Produced
    }

    if (vehicle.dtProdDate) {
      return 'brown'; // Scheduled (GM TPW or Ford Build Date)
    }

    // 1. Vehicle Ordered (Default Status - No Color)
    return '#00000000'; // No color for default state
  };



  const getETAStr = (vehicle) => {

    if (vehicle.manualETA) {
      const date = vehicle.manualETA.toDate();
      const month = String(date.getMonth() + 1).padStart(2, '0');  // Get month (0-indexed)
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }

    // Default to manufacturer ETA
    if (vehicle.dtManETA) {
      const date = vehicle.dtManETA.toDate();
      const month = String(date.getMonth() + 1).padStart(2, '0');  // Get month (0-indexed)
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    }

    return '';  // Return empty string if no ETA
  };


  const getCustomerName = (salesOrdNbr) => {
    const salesOrder = salesOrders.find(order => order.strSalesOrdNbr === salesOrdNbr);
    if (!salesOrder) return 'Unknown'; // Sales order not found
    const customer = customers.find(cust => cust.strCustNbr === salesOrder.strCustNbr);
    return customer ? customer.strCustName : 'Unknown'; // Return customer name or 'Unknown' if not found
  };

  
  const handleSortClick = (column) => {
    let direction = 'asc';

    // Toggle direction if the same column is clicked
    if (sortColumn === column && sortDirection === 'asc') {
      direction = 'desc';
    } else if (sortColumn === column && sortDirection === 'desc') {
      direction = 'asc';
    }

    setSortColumn(column);
    setSortDirection(direction);

    const sortedVehicles = [...filteredVehicles].sort((a, b) => {
      let fieldA, fieldB;

      if (column === 'customerName') {
        // Sort by customer name using getCustomerName
        fieldA = getCustomerName(a.strSalesOrdNbr).toLowerCase();
        fieldB = getCustomerName(b.strSalesOrdNbr).toLowerCase();
      } else if (column === 'dtManETA') {
        fieldA = a[column] ? new Date(a[column].toDate()) : new Date(0);
        fieldB = b[column] ? new Date(b[column].toDate()) : new Date(0);
      } else if (column === 'strStockNbr') {
        fieldA = a['strVin'] ? a['strVin'].slice(-8).toLowerCase() : '';
        fieldB = b['strVin'] ? b['strVin'].slice(-8).toLowerCase() : '';
      } else {
        fieldA = a[column]?.toString().toLowerCase() || '';
        fieldB = b[column]?.toString().toLowerCase() || '';
      }

      if (direction === 'asc') {
        return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
      } else {
        return fieldA > fieldB ? -1 : fieldA < fieldB ? 1 : 0;
      }
    });

    setFilteredVehicles(sortedVehicles);
  };

  const getDisplayedStatus = (vehicle) => {
    // 5. Delivered to Customer (Final State)
    if (vehicle.manualStatus === 'Complete') {
      return 'Delivered to Customer';
    }
    if (vehicle.manualStatus === 'In Process') {
      return 'In Process';
    }
    if (vehicle.manualStatus === 'Scheduled') {
      return 'Scheduled';
    }
    if (vehicle.manualStatus === 'Produced') {
      return 'Produced';
    }
    if (vehicle.manualStatus === 'Delivered') {
      return 'Delivered';
    }

    // 4. Process of Getting Vehicle Ready for Customer Delivery
    if (vehicle.dtCustSignedDate) {
      return 'Customer Signed and Ownership Changed';
    }

    if (vehicle.strUpfitterStatus && vehicle.strUpfitterStatus !== 'Complete') {
      return 'At Upfitter';
    }

    // 3. Ready for Customer Signature
    if (vehicle.VINStatus === 'Delivered') {
      return 'On the Ground';
    }

    if (vehicle.strUpfitterStatus && vehicle.strUpfitterStatus === 'Complete') {
      return 'Delivered to Customer';
    }

    // 2. Vehicle In Process
    if (vehicle.strManStat || vehicle.dtProdDate || vehicle.dtManETA) {
      // a. In Transit to Destination
      if (vehicle.dtManETA) {
        return 'In Transit';
      }

      // b. Produced (GM status >= 3800 or Ford status "Produced")
      if (vehicle.strManStat && (parseInt(vehicle.strManStat) >= 3800 || vehicle.strManStat === 'Produced')) {
        return 'Produced';
      }

      // c. Scheduled (TPW or Build Date is set)
      if (vehicle.dtProdDate) {
        return 'Scheduled';
      }
    }

    // 1. Vehicle Ordered (Default Status)
    if (!vehicle.strManStat && !vehicle.dtProdDate && !vehicle.dtManETA) {
      return 'Vehicle Ordered';
    }

    // Default to 'Vehicle Ordered' if no other statuses are applicable
    return 'Vehicle Ordered';
  };



  /*
  const handleCreateTeamsFolders = async () => {
    setShowTeamsModal(true);
    setTeamsLoading(true);
    setTeamsProgress(0);

    try {
      await createTeamsFoldersForVehicles(selectedVehicles, getDocument, queryData, modelCodes);
      setTeamsProgress(100);  // Assuming successful completion
    } catch (error) {
      console.error('Error creating Teams folders:', error);
    } finally {
      setTeamsLoading(false);
      setShowTeamsModal(false);
    }
  };
  */

  useEffect(() => {
    const fetchColorCodes = async () => {
      const colorCodesData = await getData('colorCodes');
      const colorCodesMap = colorCodesData.reduce((map, obj) => {
        map[obj.strColor] = obj.strColorName;
        return map;
      }, {});
      setColorCodes(colorCodesMap);
    };

    fetchColorCodes();
  }, [getData]);

  useEffect(() => {
    const fetchModelCodes = async () => {
      const modelCodesData = await getData('modelCodes');
      const modelCodesMap = modelCodesData.reduce((map, obj) => {
        map[obj.strFactory + " - " + obj.strModel] = obj.strModelName;
        return map;
      }, {});
      setModelCodes(modelCodesMap);
    };

    fetchModelCodes();
  }, [getData]);

  useEffect(() => {
    const fetchEventCodes = async () => {
      const eventCodesData = await getData('eventCodes');
      setEventCodes(eventCodesData);
    };

    fetchEventCodes();
  }, [getData]);

  useEffect(() => {
    const filterVehicles = (query) => {
      const lowercasedQuery = query.toLowerCase();
      const filtered = vehicles.filter(vehicle => {
        return (
          vehicle.strOrdNbr?.toLowerCase().includes(lowercasedQuery) ||
          vehicle.nDAN?.toString().toLowerCase().includes(lowercasedQuery) ||
          vehicle.strVin?.toLowerCase().includes(lowercasedQuery) ||
          (allVehicles && vehicle.strSalesOrdNbr?.toLowerCase().includes(lowercasedQuery)) ||
          vehicle.strModel?.toLowerCase().includes(lowercasedQuery) ||
          vehicle.strMake?.toLowerCase().includes(lowercasedQuery) ||
          getCustomerName(vehicle.strSalesOrdNbr)?.toLowerCase().includes(lowercasedQuery) // Allow searching by customer name
        );
      });

      setFilteredVehicles(filtered);
      setTotalRows(filtered.length);
      setCurrentPage(1); // Reset to the first page when filtering changes
    };
    filterVehicles(searchQuery);
  }, [vehicles, searchQuery, salesOrders, customers, allVehicles]);


  const handleVehicleRowClick = (vehicle) => {
    if (selectedVehicle && selectedVehicle.strOrdNbr === vehicle.strOrdNbr) {
      setSelectedVehicle(null); // Deselect if the same vehicle is clicked again
    } else {
      setSelectedVehicle(vehicle);
    }
  };

  const handleVehicleAdded = async () => {
    const vehicleData = selectedOrder
      ? await queryData('vehicles', 'strSalesOrdNbr', '==', selectedOrder.strSalesOrdNbr)
      : await getData('vehicles');

    setVehicles(vehicleData);
    setShowAddModal(false); // Close the modal after adding the vehicle
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value);
  };


  const handleVehicleUpdated = async (updatedVehicle) => {
    if (!updatedVehicle || !updatedVehicle.id) {
      console.error('Updated vehicle or vehicle ID is missing');
      return;
    }
    await updateData('vehicles', updatedVehicle.id, updatedVehicle);
    const updatedVehicles = vehicles.map(v => (v.id === updatedVehicle.id ? updatedVehicle : v));
    setVehicles(updatedVehicles);
    setShowBulkEditModal(false); // Close the modal after updating the vehicle
  };

  const handleBulkEditClick = () => {
    if (selectedVehicles.length === 0) {
      return;
    }
    setVehicleToEdit(selectedVehicles);
    setShowBulkEditModal(true);
  };

  const handleCSVFile = async (file) => {
    setLoading(true);
    setProgress(0);

    Papa.parse(file, {
      header: true,
      complete: async function (results) {
        const totalRows = results.data.length;
        let processedRows = 0;

        for (const row of results.data) {
          const vin = row['VIN'];
          const vinStatus = row['VIN Status'];
          let etaDate = null;

          // Check the VIN status and assign the appropriate date field
          if (vinStatus === 'Delivered') {
            etaDate = parseValidDate(row['Last Milestone Local Date']);
          } else {
            etaDate = parseValidDate(row['End Intelligent ETA Local Date']);
          }

          if (etaDate) {
            // Find the vehicle in Firestore by VIN
            const vehicleData = await queryData('vehicles', 'strVin', '==', vin);
            if (vehicleData.length > 0) {
              const vehicle = vehicleData[0];

              // Update the vehicle's dtManETA field
              await updateData('vehicles', vehicle.id, {
                dtManETA: Timestamp.fromDate(etaDate)
              });
            }
          } else {
            console.log(`Skipping vehicle with VIN ${vin}: Invalid or missing date`);
          }

          // Update progress
          processedRows++;
          setProgress(Math.round((processedRows / totalRows) * 100));
        }

        setLoading(false);
        setReload(reload + 1); // Trigger reload
      },
      error: function (error) {
        console.error('Error parsing CSV:', error);
        setLoading(false);
      }
    });
  };

  // Helper function to validate and parse dates in MM/DD/YYYY format
  const parseValidDate = (dateString) => {
    if (!dateString || typeof dateString !== 'string') return null;

    // Use regular expression to match MM/DD/YYYY format
    const regex = /^(0?[1-9]|1[0-2])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    if (!regex.test(dateString)) {
      return null; // Return null for invalid date formats
    }

    const date = new Date(dateString);
    return isNaN(date.getTime()) ? null : date; // Return null if the date is invalid
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const fileName = file.name.toLowerCase(); // Convert the file name to lowercase

    if (fileName.includes('vinview')) {
      await handleCSVFile(file); // Handle CSV file for VINView
    } else if (fileName.includes('ford') || fileName.includes('gm')) {
      const data = await file.arrayBuffer();
      const workbook = read(data, { type: 'array' });
      if (fileName.includes('ford')) {
        await handleFordFile(workbook);
      } else {
        await handleGMFile(workbook);
      }
    }
  };

  const handleVINViewFile = async (workbook) => {
    const sheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[sheetName];
    const json = utils.sheet_to_json(worksheet);

    setLoading(true);
    setProgress(0);

    // For progress tracking
    const totalRows = json.length;
    let processedRows = 0;

    for (const row of json) {
      const vin = row['VIN'];
      const vinStatus = row['VIN Status'];
      let etaDate;

      // Check the VIN status and assign the appropriate date field
      if (vinStatus === 'Delivered') {
        etaDate = excelDateToJSDate(row['Last Milestone Local Date']);
      } else {
        etaDate = excelDateToJSDate(row['End Intelligent ETA Local Date']);
      }

      // Find the vehicle in Firestore by VIN
      const vehicleData = await queryData('vehicles', 'strVin', '==', vin);
      if (vehicleData.length > 0) {
        const vehicle = vehicleData[0];

        // Update the vehicle's dtManETA field
        await updateData('vehicles', vehicle.id, {
          dtManETA: Timestamp.fromDate(etaDate)
        });
      }

      // Update progress
      processedRows++;
      setProgress(Math.round((processedRows / totalRows) * 100));
    }

    setLoading(false);
    setReload(reload + 1); // Trigger reload
  };

  const getCircleColor = (percentage) => {
    if (percentage >= 75) {
      return "green"; // High percentage = green
    } else if (percentage >= 45) {
      return "yellow"; // Medium percentage = yellow
    } else if (percentage >= 10) {
      return "orange"; // Low percentage = orange
    } else {
      return "white"; // Very low percentage = red
    }
  };


  const handleGMFile = async (workbook) => {
    const sheetNames = workbook.SheetNames;
    let totalVehicles = 0;

    // Calculate total vehicles for progress tracking
    sheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet); // No longer skipping any rows
      totalVehicles += json.length;
    });

    setLoading(true);

    for (const sheetName of sheetNames) {
      let processedVehicles = 0;
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet); // No longer skipping any rows
      const mappedVehicles = json.map(vehicle => ({ ...mapGMFields(vehicle) }));

      processedVehicles += mappedVehicles.length;
      setProgress(Math.round((processedVehicles / totalVehicles) * 100));

      await updateVehicles(mappedVehicles, setProgress, async () => {
        const vehicleData = await getData('vehicles');
        setVehicles(vehicleData);
        setFilteredVehicles(vehicleData); // Update filtered vehicles as well
      });
    }

    setLoading(false);
  };



  const handleFordFile = async (workbook) => {
    const sheetNames = workbook.SheetNames;
    let totalVehicles = 0;

    // Calculate total vehicles for progress tracking
    sheetNames.forEach(sheetName => {
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet, { range: 2 }); // Skip the first two rows
      totalVehicles += json.length;
    });

    setLoading(true);

    for (const sheetName of sheetNames) {
      let processedVehicles = 0;
      const worksheet = workbook.Sheets[sheetName];
      const json = utils.sheet_to_json(worksheet, { range: 2 }); // Skip the first two rows
      const mappedVehicles = json.map(vehicle => ({ ...mapFordFields(vehicle) }));

      processedVehicles += mappedVehicles.length;
      setProgress(Math.round((processedVehicles / totalVehicles) * 100));

      await updateVehicles(mappedVehicles, setProgress, async () => {
        const vehicleData = await getData('vehicles');
        setVehicles(vehicleData);
        setFilteredVehicles(vehicleData); // Update filtered vehicles as well
      });
    }
    setLoading(false);
  };

  const updateVehicles = async (vehicles, setProgress, callback) => {
    const vehiclesData = await getData('vehicles'); // Fetch existing vehicle data from Firestore
    const activeSalesOrders = await getData('salesOrders');
    const activeOrders = activeSalesOrders.filter(order => order.strSalesOrdStatus === 'Active');

    for (let i = 0; i < vehicles.length; i++) {
      const vehicle = vehicles[i];

      // Set current vehicle info to display
      setCurrentVehicleInfo({
        strOrdNbr: vehicle['Order'],
        strVin: vehicle['VIN'],
        strYR: vehicle['Year'],
        strMake: vehicle['Make'],
        strModel: vehicle['Model']
      });

      const result = await findVehicle(vehicle, activeOrders, vehiclesData);

      if (result.found) {
        // If vehicle is found, update it
        setCurrentVehicleStatus('Updated');
        await updateData('vehicles', result.vehicle.id, vehicle);
      } else if (result.strSalesOrdNbr) {
        // If the vehicle is new, add it
        setCurrentVehicleStatus('New');
        await addData('vehicles', {
          ...vehicle,
          strSalesOrdNbr: result.strSalesOrdNbr,
          createdDate: Timestamp.now(), // Only set `createdDate` for new vehicles
        });
      }
      // Update progress
      setProgress(Math.round(((i + 1) / vehicles.length) * 100));
    }

    // Call the callback function to update the state
    if (callback) {
      callback();
    }
  };


  const normalizePrice = (price) => {
    if (!price) return 0;

    // Remove any dollar signs ($) and commas (,)
    const normalizedPrice = price.replace(/[$,]/g, '');

    // Convert the remaining string to a floating-point number
    return parseFloat(normalizedPrice) || 0;
  };


  const findVehicle = async (importedVehicle, activeOrders, vehiclesData) => {
    let existingVehicle = null;

    // Check if the vehicle exists by VIN (ensure VIN exists and isn't empty)
    if (importedVehicle.strVin && importedVehicle.strVin.trim() !== '') {
      existingVehicle = vehiclesData.find(vehicle => vehicle.strVin === importedVehicle.strVin);
      if (existingVehicle) {
        return { found: true, vehicle: existingVehicle };
      }
    }

    // If VIN is missing or not found, check if the vehicle exists by Order # (ensure Order # exists and isn't empty)
    if (importedVehicle.strOrdNbr && importedVehicle.strOrdNbr.trim() !== '') {
      existingVehicle = vehiclesData.find(vehicle => vehicle.strOrdNbr === importedVehicle.strOrdNbr);
      if (existingVehicle) {
        return { found: true, vehicle: existingVehicle };
      }
    }

    // If no vehicle is found by VIN or Order #, look for a match in activeOrders based on DAN or Ford Order Number
    for (const order of activeOrders) {
      // Check the DAN (Dealer Account Number) in activeOrders
      if (order.arrDans && order.arrDans.includes(importedVehicle.nDAN?.toString())) {
        return { found: false, strSalesOrdNbr: order.strSalesOrdNbr };
      }

      // Check for matching Order Numbers in activeOrders (this will use strOrdNbr from the vehicle object)
      if (order.arrFordOrders && order.arrFordOrders.includes(importedVehicle.strOrdNbr?.toString())) {
        return { found: false, strSalesOrdNbr: order.strSalesOrdNbr };
      }
    }

    // If no match is found, return as not found with no Sales Order Number
    return { found: false, strSalesOrdNbr: null };
  };


  const excelDateToJSDate = (serial) => {
    if (isNaN(serial)) {
      return null;
    }
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    return new Date(utc_value * 1000);
  };

  const mapGMFields = (vehicle) => {
    const eventDate = excelDateToJSDate(vehicle["Event Date"]);
    const estimatedDeliveryDate = excelDateToJSDate(vehicle["Estimated Delivery Date"]);
    const currentEventDate = excelDateToJSDate(vehicle["Current Event Date"]);
    const prodDate = excelDateToJSDate(vehicle["TPW"]);

    return {
      strOrdNbr: String(vehicle["Order #"]) || '',
      strGMConfigID: vehicle["GM Config ID"] || '',
      strOrderType: vehicle["Order Type"] || '',
      curVehMSRP: normalizePrice(vehicle["MSRP w/DFC†"]) || 0,
      dtProdDate: prodDate || null,
      strFactory: "GM",
      strMake: vehicle["Division"] || '',
      strModel: vehicle["Model"] || '',
      strColor: vehicle["Color"] || '',
      strManStat: vehicle["Current Event"] || '',
      dtManETA: eventDate || null,
      strCustName: vehicle["Business Customer Name"] || '',
      strYR: vehicle["Model Year"] || '',
      strDelivStat: vehicle["Deliver Vehicle"] || '',
      curVehIncent: vehicle["Lookup Incentives by VIN"] || 0,
      lstrCustNotes: vehicle["Remarks"] || '',
      dtEstDelivery: estimatedDeliveryDate || null,
      dtCurrentEvent: currentEventDate || null,
      nDAN: vehicle["DAN"] || '',
      strVin: vehicle["VIN"] || '',
      createdDate: Timestamp.now(), // Add this line for the current Firestore timestamp
    };
  };

  const mapFordFields = (vehicle) => {
    const buildDate = excelDateToJSDate(vehicle["BuildDate"]);
    const receiptDate = excelDateToJSDate(vehicle["Receipt Date"]);

    return {
      strOrdNbr: String(vehicle["Order"]) || '',
      strGMConfigID: vehicle["Reservation ID"] || '', // Assuming this as a placeholder for Ford Config ID
      strOrderType: vehicle["Type"] || '',
      strFactory: 'Ford', // Make is Ford for all Ford reports
      strMake: 'Ford',
      strModel: vehicle["Body"] || '',
      strColor: vehicle["Paint"] || '',
      strManStat: vehicle["Status"] || '',
      dtManETA: buildDate || null,
      strYR: vehicle["Year"] || '',
      strDelivStat: '', // Delivery Status not provided in Ford headers
      curVehIncent: 0, // Incentives not provided in Ford headers
      lstrCustNotes: vehicle["SVS Reason"] || '',
      dtEstDelivery: receiptDate || null,
      dtCurrentEvent: buildDate || null, // Assuming build date as current event date
      nDAN: '', // DAN not provided in Ford headers
      strVin: vehicle["VIN"] || '',
      createdDate: Timestamp.now(),
    };
  };

  const handleDeleteClick = (vehicle) => {
    setShowDeleteModal(true);
  };

  const handleDetailsClick = (vehicle) => {
    setVehicleToShow(vehicle);
    setShowDetailModal(true);
  };

  const handleAddClick = () => {
    setShowAddModal(true);
  };

  const getEventCode = (strManStat) => {
    try {
      const eventCode = eventCodes.find(code => code.strManStat === strManStat.toString());
      return eventCode ? eventCode : null;
    } catch (error) {
      return null;
    }
  };

  const confirmDelete = async () => {
    try {
      // Loop through each selected vehicle and delete them one by one
      for (const vehicle of selectedVehicles) {
        await deleteData('vehicles', vehicle.id);
      }

      // Filter out the deleted vehicles from the state
      const updatedVehicles = vehicles.filter(vehicle => !selectedVehicles.includes(vehicle));
      setVehicles(updatedVehicles);
      setFilteredVehicles(updatedVehicles); // Ensure UI sync
      setTotalRows(updatedVehicles.length);
      setSelectedVehicles([]); // Clear the selection

      setShowDeleteModal(false); // Close the modal after deletion
    } catch (error) {
      console.error("Error deleting vehicles:", error);
    }
  };

  const toggleVehicleSelection = (vehicle) => {
    if (selectedVehicles.includes(vehicle)) {
      setSelectedVehicles(selectedVehicles.filter(v => v !== vehicle));
    } else {
      setSelectedVehicles([...selectedVehicles, vehicle]);
    }
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedVehicles([]);
    } else {
      setSelectedVehicles(currentRows);
    }
    setSelectAll(!selectAll);
  };

  const handleBulkEditHide = () => {
    setSelectedVehicles([]);
    setShowBulkEditModal(false)
  }

  const handleBulkEditSubmit = async (updatedFields) => {
    const promises = selectedVehicles.map(vehicle => {
      // Create an updated vehicle object with only changed fields
      const updatedVehicle = {};

      // Loop through the updatedFields and only add the fields that have changed or are not empty
      Object.keys(updatedFields).forEach(field => {
        if (updatedFields[field] !== '' && updatedFields[field] !== vehicle[field]) {
          updatedVehicle[field] = updatedFields[field]; // Add only changed fields
        }
      });

      // Update only if there are fields to update
      if (Object.keys(updatedVehicle).length > 0) {
        return updateData('vehicles', vehicle.id, updatedVehicle);
      }

      return Promise.resolve(); // No changes, so return a resolved promise
    });

    try {
      await Promise.all(promises);
      const vehicleData = selectedOrder
        ? await queryData('vehicles', 'strSalesOrdNbr', '==', selectedOrder.strSalesOrdNbr)
        : await getData('vehicles');
      setVehicles(vehicleData);
      setShowBulkEditModal(false);
    } catch (error) {
      console.error('Error updating vehicles:', error);
    }
  };


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredVehicles.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  return (
    <>
      <InputGroup className="mb-2">
        <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search vehicles..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </InputGroup>

      {/* Wrap the table in a responsive container */}
      <div className="table-responsive">
        <Table hover className="mt-3" style={{ whiteSpace: 'nowrap' }}>
          <thead>
            <tr>
              {addButtonActive && (
                <th>
                  <div className="d-flex align-items-center">
                    <Form.Check
                      type="checkbox"
                      checked={selectAll}
                      onChange={toggleSelectAll}
                      className="me-2"
                    />
                    {!allVehicles && (
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Add Vehicle</Tooltip>}
                      >
                        <Button
                          onClick={() => handleAddClick()}
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                          className="icon-hover"
                          style={{ padding: '2px 4px' }}
                          variant="link"
                        >
                          {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                        </Button>
                      </OverlayTrigger>
                    )}

                    {/* Import Vehicles Button */}
                    {allVehicles && (
                      <>
                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip-top">Import Vehicles</Tooltip>}
                        >
                          <Button
                            onClick={() => document.getElementById('file-input').click()}
                            variant="white"
                            style={{ padding: '0px 0px' }}
                          >
                            <ArrowBarUp size={20} color="blue" />
                          </Button>
                        </OverlayTrigger>
                        <input
                          type="file"
                          id="file-input"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                          accept=".xlsx, .xls"
                        />
                      </>
                    )}

                    {/* Bulk Edit Button */}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Edit Vehicle(s)</Tooltip>}
                    >
                      <Button
                        onClick={() => handleBulkEditClick()}
                        variant="white"
                        style={{ padding: '0px 0px' }}
                      >
                        <Wrench size={18} color="black" />
                      </Button>
                    </OverlayTrigger>

                    {/* Bulk Delete Button */}
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-top">Delete Vehicle(s)</Tooltip>}
                    >
                      <Button
                        onClick={() => handleDeleteClick(selectedVehicles)}
                        variant="white"
                        style={{ padding: '0px 0px' }}
                      >
                        <Trash size={18} color="red" />
                      </Button>
                    </OverlayTrigger>
                  </div>
                </th>
              )}
              {allVehicles && (
                <th>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>SO #</span>
                    <Button onClick={() => handleSortClick('strSalesOrdNbr')} variant='white'><ArrowDownUp /></Button>
                  </div>
                </th>
              )}
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Order #</span>
                  <Button onClick={() => handleSortClick('strOrdNbr')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>DAN</span>
                  <Button onClick={() => handleSortClick('nDAN')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              {/*
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>VIN</span>
                  <Button onClick={() => handleSortClick('strVin')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              */}
              {allVehicles && (
                <th>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Customer Name</span>
                    <Button onClick={() => handleSortClick('customerName')} variant='white'>
                      <ArrowDownUp />
                    </Button>
                  </div>
                </th>
              )}
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Stock #</span>
                  <Button onClick={() => handleSortClick('strStockNbr')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Make</span>
                  <Button variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Model</span>
                  <Button onClick={() => handleSortClick('strModel')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>ETA</span>
                  <Button onClick={() => handleSortClick('dtManETA')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Status</span>
                  <Button onClick={() => handleSortClick('strManStat')} variant='white'><ArrowDownUp /></Button>
                </div>
              </th>
              <th>
                <div style={{ position: 'relative', display: 'inline-block', width: '30px', height: '30px' }}>
                  {/* Circle Icon */}
                  <CircleFill
                    size={30}
                    color={getCircleColor(calculateGreenPercentage())}  // Use the status color
                  />
                  {/* Text inside the circle */}
                  <span style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    fontSize: '12px',
                    fontWeight: 'bold',
                  }}>
                    {calculateGreenPercentage()}{"%"} {/* Replace `someText` with the desired text */}
                  </span>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {currentRows.map(vehicle => (
              <tr key={vehicle.id} onClick={() => handleVehicleRowClick(vehicle)} className="align-middle">
                {addButtonActive && (
                  <td>
                    <div className="d-flex align-items-center">
                      <Form.Check
                        type="checkbox"
                        className="me-2"
                        checked={selectedVehicles.includes(vehicle)}
                        onChange={() => toggleVehicleSelection(vehicle)}
                      />
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-top">Details</Tooltip>}
                      >
                        <Button
                          onClick={() => handleDetailsClick(vehicle)}
                          variant="white"
                          style={{ padding: '0px 0px' }}
                        >
                          <InfoCircle color="blue" />
                        </Button>
                      </OverlayTrigger>
                    </div>
                  </td>
                )}
                {allVehicles &&
                  <td>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Navigate to Sales Order</Tooltip>}>
                      <td onClick={() => handleSalesOrderClick(vehicle.strSalesOrdNbr)}>{vehicle.strSalesOrdNbr}</td>
                    </OverlayTrigger>
                  </td>
                  }
                <td>{vehicle.strOrdNbr}</td>
                <td>{vehicle.nDAN}</td>
                {allVehicles && 
                  <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{getCustomerName(vehicle.strSalesOrdNbr)}</Tooltip>}>
                      <span>{getCustomerName(vehicle.strSalesOrdNbr)}</span>
                    </OverlayTrigger>
                  </td>
                }
                {/*<td>{vehicle.strVin}</td>*/}
                <td>{vehicle.strStockNbr ? vehicle.strStockNbr : vehicle.strVin.slice(-8)}</td>
                <td>{vehicle.strYR} {vehicle.strMake}</td>
                <td>
                {modelCodes[vehicle.strFactory + " - " + vehicle.strModel] || modelCodes[vehicle.strFactory + " - " + vehicle.strModel.substring(0, 3)] || vehicle.strModel}</td>
                <td>{getETAStr(vehicle)}</td>
                <td style={{ maxWidth: '50px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">{getDisplayedStatus(vehicle)}</Tooltip>}>
                    <span>{getDisplayedStatus(vehicle)}</span>
                  </OverlayTrigger>                
                </td>
                <td>
                  <CircleFill
                    size={30}
                    color={getStatusColor(vehicle)}  // Calculate the color dynamically based on progress
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      <div className={`d-flex justify-content-between align-items-center my-3 ${selectedOrder ? 'mb-1' : (rowsPerPage === 15 ? 'mb-3' : 'mb-5')}`} style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
            <option value={250}>250</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      {loading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          <Spinner animation="border" variant="primary" />
          <ProgressBar now={progress} label={`${progress}%`} style={{ width: '50%', marginTop: '20px' }} />
          {/*currentVehicleInfo && (
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              <strong>{currentVehicleStatus}: </strong>
              {currentVehicleInfo.strOrdNbr} / {currentVehicleInfo.strVin} / {currentVehicleInfo.strYR} / {currentVehicleInfo.strMake} / {currentVehicleInfo.strModel}
            </div>
          )*/}
        </div>
      )}


      {/* Delete Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedVehicles.length > 1
            ? `Are you sure you want to delete these ${selectedVehicles.length} vehicles?`
            : "Are you sure you want to delete this vehicle?"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={confirmDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>

      {/* Add Modal */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Add Vehicle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VehicleForm
            salesOrderNumber={(allVehicles ? null : selectedOrder?.strSalesOrdNbr)}
            onVehicleAdded={handleVehicleAdded}
            modalCodes={modelCodes}
          />
        </Modal.Body>
      </Modal>

      {/* Detail Modal */}
      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Vehicle Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {vehicleToShow && (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              <Row>
                <Col><strong>Vehicle Date:</strong></Col>
                <Col>
                  {vehicleToShow.createdDate
                    ? vehicleToShow.createdDate.toDate().toISOString().split('T')[0]
                    : 'N/A'}
                </Col>
              </Row>
              <Row>
                <Col><strong>Order Number:</strong></Col>
                <Col>{vehicleToShow.strOrdNbr}</Col>
              </Row>
              <Row>
                <Col><strong>Sales Order Number:</strong></Col>
                <Col>{vehicleToShow.strSalesOrdNbr}</Col>
              </Row>
              <Row>
                <Col><strong>DAN:</strong></Col>
                <Col>{vehicleToShow.nDAN}</Col>
              </Row>
              <Row>
                <Col><strong>PO Number:</strong></Col>
                <Col>{vehicleToShow.strPONbr}</Col>
              </Row>
              <Row>
                <Col><strong>PO Line Number:</strong></Col>
                <Col>{vehicleToShow.strPOLineNbr}</Col>
              </Row>
              <Row>
                <Col><strong>VIN:</strong></Col>
                <Col>{vehicleToShow.strVin}</Col>
              </Row>
              <Row>
                <Col><strong>Stock Number:</strong></Col>
                <Col>{vehicleToShow.strVin.slice(-8)}</Col>
              </Row>
              <Row>
                <Col><strong>Year:</strong></Col>
                <Col>{vehicleToShow.strYR}</Col>
              </Row>
              <Row>
                <Col><strong>Manufacturer Status Code:</strong></Col>
                <Col>{vehicleToShow.strManStat}</Col>
              </Row>
              <Row>
                <Col><strong>Upfitter Number:</strong></Col>
                <Col>{vehicleToShow.strUpfitterNbr}</Col>
              </Row>
              <Row>
                <Col><strong>Upfitter Status Code:</strong></Col>
                <Col>{vehicleToShow.strUpfitterStatus}</Col>
              </Row>
              <Row>
                <Col><strong>Upfitter ETA Date:</strong></Col>
                <Col>{vehicleToShow.dtUpfitterETA}</Col>
              </Row>
              <Row>
                <Col><strong>Upfitter Complete Date:</strong></Col>
                <Col>{vehicleToShow.dtUpfitterComp}</Col>
              </Row>
              <Row>
                <Col><strong>Manufacturer ETA:</strong></Col>
                <Col>{vehicleToShow.dtManETA?.toDate().toLocaleDateString() || 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>Drop Indicator:</strong></Col>
                <Col>{vehicleToShow.bDropInd ? 'Yes' : 'No'}</Col>
              </Row>
              <Row>
                <Col><strong>Received Date:</strong></Col>
                <Col>{vehicleToShow.dtRecvDate?.toDate().toLocaleDateString() || 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>Drop Location:</strong></Col>
                <Col>{vehicleToShow.strDropLoc}</Col>
              </Row>
              <Row>
                <Col><strong>Delivery Status:</strong></Col>
                <Col>{vehicleToShow.strDelivStat}</Col>
              </Row>
              <Row>
                <Col><strong>Delivery Date:</strong></Col>
                <Col>{vehicleToShow.dtDelivDate?.toDate().toLocaleDateString() || 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>Delivery ETA:</strong></Col>
                <Col>{vehicleToShow.dtDelivETA?.toDate().toLocaleDateString() || 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>PDI Date:</strong></Col>
                <Col>{vehicleToShow.dtPDI?.toDate().toLocaleDateString() || 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>Billed CDK:</strong></Col>
                <Col>{vehicleToShow.bBillCDK ? 'Yes' : 'No'}</Col>
              </Row>
              <Row>
                <Col><strong>Report Date:</strong></Col>
                <Col>{vehicleToShow.dtRptDate?.toDate().toLocaleDateString() || 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>Recall:</strong></Col>
                <Col>{vehicleToShow.bRecall ? 'Yes' : 'No'}</Col>
              </Row>
              <Row>
                <Col><strong>Deal Number:</strong></Col>
                <Col>{vehicleToShow.strDealNbr}</Col>
              </Row>
              <Row>
                <Col><strong>Sale Date:</strong></Col>
                <Col>{vehicleToShow.dtSaleDate?.toDate().toLocaleDateString() || 'N/A'}</Col>
              </Row>
              <Row>
                <Col><strong>Vehicle MSRP:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curVehMSRP || 0)}</Col>
              </Row>
              <Row>
                <Col><strong>Vehicle Sale Price:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curVehSalePrice)}</Col>
              </Row>
              <Row>
                <Col><strong>Vehicle COS:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curVehCOS)}</Col>
              </Row>
              <Row>
                <Col><strong>Vehicle Incentive:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curVehIncent)}</Col>
              </Row>
              <Row>
                <Col><strong>Warranty Sale Price:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curWarrSale)}</Col>
              </Row>
              <Row>
                <Col><strong>Warranty Cost:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curWarrCost)}</Col>
              </Row>
              <Row>
                <Col><strong>Warranty Type:</strong></Col>
                <Col>{vehicleToShow.strWarrType}</Col>
              </Row>
              <Row>
                <Col><strong>DOAS:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curDoas)}</Col>
              </Row>
              <Row>
                <Col><strong>Keys:</strong></Col>
                <Col>{vehicleToShow.curKeys}</Col>
              </Row>
              <Row>
                <Col><strong>Accessories:</strong></Col>
                <Col>{vehicleToShow.currAccess}</Col>
              </Row>
              <Row>
                <Col><strong>VQ2:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curVQ2)}</Col>
              </Row>
              <Row>
                <Col><strong>Tag and Title Fees:</strong></Col>
                <Col>{formatCurrency(vehicleToShow.curTTFees)}</Col>
              </Row>
              <Row>
                <Col><strong>Bill Notes:</strong></Col>
                <Col>{vehicleToShow.strBillNotes}</Col>
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>


      {/* Bulk Edit Modal */}
      <Modal show={showBulkEditModal} onHide={handleBulkEditHide} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit Vehicle(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VehicleForm
            salesOrderNumber={allVehicles ? null : selectedOrder?.strSalesOrdNbr}
            vehicle={vehicleToEdit}
            onVehicleAdded={handleVehicleAdded}
            onVehicleUpdated={(updatedVehicle) => {
              handleVehicleUpdated(updatedVehicle);
              setShowBulkEditModal(false); // Close the modal after updating
              setSelectedVehicles([]);
              setReload(reload + 1);
            }}
            onBulkEditSubmit={(updatedFields) => {
              handleBulkEditSubmit(updatedFields);
              setShowBulkEditModal(false); // Close the modal after bulk editing
              setSelectedVehicles([]);
              setReload(reload + 1);
            }}
            modelCodes={modelCodes}
          />
        </Modal.Body>
      </Modal>

      {/* Teams Progress Modal */}
      <Modal show={showTeamsModal} onHide={() => setShowTeamsModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Teams Folders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {teamsLoading ? (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
              <ProgressBar now={teamsProgress} label={`${teamsProgress}%`} className="mt-3" />
            </div>
          ) : (
            <div>Folders have been successfully created!</div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );


}

export default VehicleList;
