// src/contexts/FirestoreContext.js
import React, { useContext, useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, addDoc, getDocs, updateDoc, doc, getDoc, deleteDoc, query, where, onSnapshot } from 'firebase/firestore';

const FirestoreContext = React.createContext();

export function useFirestore() {
  return useContext(FirestoreContext);
}

export function FirestoreProvider({ children }) {
  const [loading, setLoading] = useState(true);

  const addData = async (collectionName, data) => {
    const docRef = await addDoc(collection(db, collectionName), data);
    return docRef.id;
  };

  const getData = async (collectionName) => {
    const querySnapshot = await getDocs(collection(db, collectionName));
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  const queryData = async (collectionName, field, operator, value) => {
    const q = query(collection(db, collectionName), where(field, operator, value));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  };

  const getDocument = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      throw new Error(`No document found for id: ${docId}`);
    }
  };

  const updateData = async (collectionName, docId, newData) => {
    if (!collectionName || !docId) {
      throw new Error('Collection name or Document ID is undefined');
    }
    const docRef = doc(db, collectionName, docId);
    await updateDoc(docRef, newData);
  };

  const deleteData = async (collectionName, docId) => {
    const docRef = doc(db, collectionName, docId);
    await deleteDoc(docRef);
  };

  const onSnapshotData = (collectionName, callback) => {
    return onSnapshot(collection(db, collectionName), (snapshot) => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(data);
    });
  };

  const onSnapshotQueryData = (collectionName, field, operator, value, callback) => {
    const q = query(collection(db, collectionName), where(field, operator, value));
    return onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      callback(data);
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const value = {
    addData,
    getData,
    queryData,
    getDocument, // Ensure getDocument is included in the context
    updateData,
    deleteData,
    onSnapshotData, // Add the onSnapshotData function
    onSnapshotQueryData // Add the onSnapshotQueryData function
  };

  return (
    <FirestoreContext.Provider value={value}>
      {!loading && children}
    </FirestoreContext.Provider>
  );
}
