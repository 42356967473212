// src/pages/HomePage.js
import React, { useRef, useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Container, Form, Button, Alert } from 'react-bootstrap';

function HomePage() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, currentUser } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [role, setRole] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          setRole(idTokenResult.claims.role);
        }
      });
    }
  }, [currentUser]);

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);

      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const idTokenResult = await user.getIdTokenResult(true); // Force refresh the token
        const role = idTokenResult.claims.role;

        if (role === 'Customer') {
          navigate('/customer');
        } else if (role === 'Admin') {
          navigate('/salesorders');
        } else {
          navigate('/');
        }
      }
    } catch {
      setError('Failed to log in');
    }

    setLoading(false);
  }

  return (
    <div>
      <Container className="text-center mt-5">
        <h1>Welcome to Fleet Sales System</h1>
        <p>Your one-stop solution for managing fleet sales.</p>
      </Container>
      {!currentUser && (
        <Container className="d-flex align-items-center justify-content-center mt-5">
          <div className="w-100" style={{ maxWidth: '500px' }}>
            <h2 className="text-center mb-4">Sign In</h2>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email" ref={emailRef} required />
              </Form.Group>
              <Form.Group className="mb-3" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" ref={passwordRef} required />
              </Form.Group>
              <Button type="submit" disabled={loading}>Login</Button>
            </Form>
          </div>
        </Container>
      )}
    </div>
  );
}

export default HomePage;
