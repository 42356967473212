import React, { useRef, useState, useEffect } from 'react';
import { Button, Form, FloatingLabel } from 'react-bootstrap';
import { httpsCallable } from 'firebase/functions';
import { functions, db } from '../firebaseConfig';  // Ensure this path is correct
import { useFirestore } from '../contexts/FirestoreContext';
import { doc, updateDoc } from 'firebase/firestore'; // Import Firestore update methods
import Select from 'react-select'; // Import React-Select


function UserForm({ onClose, user, onUserUpdated }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [upfitters, setUpfitters] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState('None');
  const [selectedUpfitter, setSelectedUpfitter] = useState('None');
  const [selectedRole, setSelectedRole] = useState('Customer');
  const { getData } = useFirestore();
  const [selectedCustomers, setSelectedCustomers] = useState([]);


  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef(); // Used for new password if provided

  useEffect(() => {
    const fetchData = async () => {
      const customerData = await getData('customers');
      const upfitterData = await getData('Upfitters');
      setCustomers(customerData);
      setUpfitters(upfitterData);

      if (user) {
        if (nameRef.current) nameRef.current.value = user.name || '';
        if (emailRef.current) emailRef.current.value = user.email || '';

        setSelectedCustomers(user.customers || []);  // Initialize with an array of customers
        setSelectedUpfitter(user.upfitter || 'None');
        setSelectedRole(user.role || 'Customer');
      }
    };
    fetchData();
  }, [getData, user]);


  async function handleSubmit(e) {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      if (user) {
        // Edit existing user
        const updatedUser = {
          ...user,
          name: nameRef.current.value,
          email: emailRef.current.value,
          customers: selectedCustomers, // Store selected customers as array
          upfitter: selectedUpfitter,
          role: selectedRole,
        };

        // Update user in Firestore
        const userRef = doc(db, 'users', user.id);
        await updateDoc(userRef, updatedUser);

        // Update password if provided
        const newPassword = passwordRef.current?.value;
        if (newPassword) {
          const updateUserPassword = httpsCallable(functions, 'updateUser');
          await updateUserPassword({
            uid: user.id,
            password: newPassword,
          });
        }

        if (onUserUpdated) {
          onUserUpdated(updatedUser);
        }
      } else {
        // Add new user
        const createUser = httpsCallable(functions, 'createUser');
        await createUser({
          email: emailRef.current.value,
          password: passwordRef.current.value,
          name: nameRef.current.value,
          customers: selectedCustomers,  // Now stores an array of strCustNbr
          upfitter: selectedUpfitter,
          role: selectedRole,
        });
      }

      onClose();
    } catch (error) {
      setError('Failed to save user: ' + error.message);
      console.error("Error creating/updating user: ", error);
    }

    setLoading(false);
  }


  const handleCustomerChange = (selectedOptions) => {
    setSelectedCustomers(selectedOptions ? selectedOptions.map(option => option.value) : []);
  };


  const handleUpfitterChange = (e) => {
    setSelectedUpfitter(e.target.value);
  };

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
  };

  return (
    <div className="card mt-4">
      <div className="card-body">
        {error && <div className="alert alert-danger">{error}</div>}
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="name">
            <FloatingLabel controlId="floatingName" label="Name">
              <Form.Control type="text" ref={nameRef} required />
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="email">
            <FloatingLabel controlId="floatingEmail" label="Email">
              <Form.Control type="email" ref={emailRef} required />
            </FloatingLabel>
          </Form.Group>
          {!user && (
            <Form.Group className="mb-3" controlId="password">
              <FloatingLabel controlId="floatingPassword" label="Password">
                <Form.Control type="password" ref={passwordRef} required />
              </FloatingLabel>
            </Form.Group>
          )}
          {user && (
            <Form.Group className="mb-3" controlId="newPassword">
              <FloatingLabel controlId="floatingNewPassword" label="New Password (Optional)">
                <Form.Control type="password" ref={passwordRef} />
              </FloatingLabel>
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="customer">
            <Form.Label>Customers</Form.Label>
            <Select
              isMulti
              value={customers
                .filter(cust => selectedCustomers.includes(cust.strCustNbr))
                .map(cust => ({ value: cust.strCustNbr, label: cust.strCustName }))}
              onChange={handleCustomerChange}
              options={customers.map(cust => ({ value: cust.strCustNbr, label: cust.strCustName }))}
              styles={{
                control: (provided) => ({ ...provided, zIndex: 9999 }),  // Set higher z-index for dropdown
                menu: (provided) => ({ ...provided, zIndex: 9999 }),     // Ensure dropdown stays on top
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="upfitter">
            <FloatingLabel controlId="floatingUpfitter" label="Upfitter">
              <Form.Select value={selectedUpfitter} onChange={handleUpfitterChange}>
                {upfitters.map(upfitter => (
                  <option key={upfitter.strUpfitterNbr} value={upfitter.strUpfitterNbr}>
                    {upfitter.strUpfitterName}
                  </option>
                ))}
                <option value="None">None</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Form.Group className="mb-3" controlId="role">
            <FloatingLabel controlId="floatingRole" label="Role">
              <Form.Select value={selectedRole} onChange={handleRoleChange}>
                <option value="Admin">Admin</option>
                <option value="Customer">Customer</option>
                <option value="Upfitter">Upfitter</option>
              </Form.Select>
            </FloatingLabel>
          </Form.Group>
          <Button variant="primary" type="submit" disabled={loading}>
            {user ? 'Update User' : 'Add User'}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default UserForm;
