import React, { useRef, useState, useEffect } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import { Form, Button, Col, Row, FloatingLabel } from 'react-bootstrap';

function CustomerForm({ customer, onCustomerUpdated, onCustomerAdded, onModalClose, registerCleanup }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { addData, updateData, queryData } = useFirestore();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const customerNbrRef = useRef();
  const customerNameRef = useRef();
  const customerNotesRef = useRef();
  const cdkStoreRef = useRef();
  const cdkCustNbrFordRef = useRef();
  const cdkCustNbrGMRef = useRef();


  // New address field refs
  const addressLine1Ref = useRef();
  const addressLine2Ref = useRef();
  const cityRef = useRef();
  const stateRef = useRef();
  const zipRef = useRef();

  useEffect(() => {
    if (customer) {
      if (customerNbrRef.current) customerNbrRef.current.value = customer.strCustNbr;
      if (customerNameRef.current) customerNameRef.current.value = customer.strCustName;
      if (customerNotesRef.current) customerNotesRef.current.value = customer.lstrCustNotes;
      if (cdkStoreRef.current) cdkStoreRef.current.value = customer.nCDKStore;

      // Populate address fields
      if (addressLine1Ref.current) addressLine1Ref.current.value = customer.strAddressLine1 || '';
      if (addressLine2Ref.current) addressLine2Ref.current.value = customer.strAddressLine2 || '';
      if (cityRef.current) cityRef.current.value = customer.strCity || '';
      if (stateRef.current) stateRef.current.value = customer.strState || '';
      if (zipRef.current) zipRef.current.value = customer.strZip || '';
    }
  }, [customer]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const customerNumber = customerNbrRef.current.value;

      // Check if the customer already exists based on the customer number
      const existingCustomer = await queryData('customers', 'strCustNbr', '==', customerNumber);

      if (existingCustomer.length > 0 && !customer) { // If a customer already exists and we're not updating
        setError(`Customer with number ${customerNumber} already exists.`);
        setLoading(false);
        return;
      }

      const newCustomer = {
        strCustNbr: customerNumber,
        strCustName: customerNameRef.current.value,
        lstrCustNotes: customerNotesRef.current.value,
        nCDKStore: cdkStoreRef.current.value,
        strCDKCustNbrFord: cdkCustNbrFordRef.current.value,
        strCDKCustNbrGM: cdkCustNbrGMRef.current.value,

        // Address fields
        strAddressLine1: addressLine1Ref.current.value,
        strAddressLine2: addressLine2Ref.current.value,
        strCity: cityRef.current.value,
        strState: stateRef.current.value,
        strZip: zipRef.current.value
      };


      if (customer) {
        await updateData('customers', customer.id, newCustomer);
        onCustomerUpdated(newCustomer);
      } else {
        await addData('customers', newCustomer);
        if (onCustomerAdded) {
          onCustomerAdded(newCustomer);
        }
      }

    } catch (err) {
      setError('Failed to save customer');
      console.error("Error updating/adding customer:", err);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="mt-4" style={{ width: '100%', margin: '0 auto' }}>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="customerNbr">
              <FloatingLabel controlId="customerNbr" label="Customer Number">
                <Form.Control type="text" ref={customerNbrRef} disabled={!!customer} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="customerName">
              <FloatingLabel controlId="customerName" label="Customer Name">
                <Form.Control type="text" ref={customerNameRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="cdkStore">
              <FloatingLabel controlId="cdkStore" label="CDK Store">
                <Form.Control type="number" ref={cdkStoreRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="cdkCustNbrFord">
              <FloatingLabel controlId="cdkCustNbrFord" label="CDK Customer Number (FORD)">
                <Form.Control type="text" ref={cdkCustNbrFordRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="cdkCustNbrGM">
              <FloatingLabel controlId="cdkCustNbrGM" label="CDK Customer Number (GM)">
                <Form.Control type="text" ref={cdkCustNbrGMRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        {/* New address fields */}
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="addressLine1">
              <FloatingLabel controlId="addressLine1" label="Address Line 1">
                <Form.Control type="text" ref={addressLine1Ref} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3" controlId="addressLine2">
              <FloatingLabel controlId="addressLine2" label="Address Line 2">
                <Form.Control type="text" ref={addressLine2Ref} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="city">
              <FloatingLabel controlId="city" label="City">
                <Form.Control type="text" ref={cityRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="state">
              <FloatingLabel controlId="state" label="State">
                <Form.Control type="text" ref={stateRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={4}>
            <Form.Group className="mb-3" controlId="zip">
              <FloatingLabel controlId="zip" label="ZIP Code">
                <Form.Control type="text" ref={zipRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="customerNotes">
              <FloatingLabel controlId="customerNotes" label="Customer Notes">
                <Form.Control as="textarea" ref={customerNotesRef} style={{ height: '100px' }} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="primary" type="submit" disabled={loading || buttonsDisabled} className="mt-3">
          {customer ? 'Update Customer' : 'Add Customer'}
        </Button>
      </Form>
    </div>
  );
}

export default CustomerForm;
