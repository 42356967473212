// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SalesOrderPage from './pages/SalesOrderPage';
import VehiclePage from './pages/VehiclePage';
import AdminPage from './pages/AdminPage';
import CustomerPortalPage from './pages/CustomerPortalPage';
import ReportingDashboardPage from './pages/ReportingDashboardPage';
import LogoutPage from './pages/LogoutPage'; // Import the LogoutPage component
import Header from './components/Header';
import Footer from './components/Footer';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <Header />
      <div className="mt-2">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/salesorders" element={<PrivateRoute roles={['Admin']}><SalesOrderPage /></PrivateRoute>} />
          <Route path="/vehicles" element={<PrivateRoute roles={['Admin']}><VehiclePage /></PrivateRoute>} />
          <Route path="/admin" element={<PrivateRoute roles={['Admin']}><AdminPage /></PrivateRoute>} />
          <Route path="/customer" element={<PrivateRoute roles={['Customer']}><CustomerPortalPage /></PrivateRoute>} />
          <Route path="/dashboard" element={<PrivateRoute roles={['Admin']}><ReportingDashboardPage /></PrivateRoute>} />
          <Route path="/logout" element={<LogoutPage />} /> {/* Add the logout route */}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
