import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, OverlayTrigger, Tooltip, Form, InputGroup, FormControl, Pagination } from 'react-bootstrap';
import { useFirestore } from '../contexts/FirestoreContext';
import UserForm from './UserForm';
import { InfoCircle, Trash, Wrench, Search, ArrowDownUp, PlusCircle, PlusCircleFill } from "react-bootstrap-icons";

function UserList() {
  const [users, setUsers] = useState([]);
  const [customers, setCustomers] = useState([]); // Add customers state
  const [upfitters, setUpfitters] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToShow, setUserToShow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  const { getData, deleteData } = useFirestore();
  const [isHovered, setIsHovered] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      const userData = await getData('users');
      const customerData = await getData('customers'); // Fetch customers data
      const upfitterData = await getData('Upfitters'); // Fetch upfitters data
      setUsers(userData);
      setCustomers(customerData); // Store customers in state
      setUpfitters(upfitterData); // Store upfitters in state
      setFilteredUsers(userData);
      setTotalRows(userData.length);
    };

    fetchData();
  }, [getData]);


  // Match the user.upfitter (which is strUpfitterNbr) to upfitter.strUpfitterName
  const getUpfitterName = (upfNbr) => {
    // If the upfitter number is blank or 'None', return 'None'
    if (!upfNbr || upfNbr === 'None') {
      return 'None';
    }

    // Find the upfitter by upfitter number
    const upfitter = upfitters.find(u => u.strUpfitterNbr === upfNbr);

    // Return the upfitter name if found, otherwise return 'Unknown Upfitter'
    return upfitter ? upfitter.strUpfitterName : 'Unknown Upfitter';
  };


  // Match the user.customer (which is strCustNbr) to customer.strCustName
  const getCustomerName = (custNbr) => {
    // If the customer number is blank or 'None', return 'None'
    if (!custNbr || custNbr === 'None') {
      return 'None';
    }

    // Find the customer by customer number
    const customer = customers.find(c => c.strCustNbr === custNbr);

    // Return the customer name if found, otherwise return 'Unknown Customer'
    return customer ? customer.strCustName : 'Unknown Customer';
  };

  const getCustomerNames = (custNbrs) => {
    if (!custNbrs || custNbrs.length === 0) return 'None';

    // Ensure custNbrs is an array, even if it's a single string
    const custNbrArray = Array.isArray(custNbrs) ? custNbrs : [custNbrs];

    const customerNames = custNbrArray.map(custNbr => {
      const customer = customers.find(c => c.strCustNbr === custNbr);
      return customer ? customer.strCustName : 'Unknown Customer';
    });

    return customerNames.join(', '); // Join the customer names with commas
  };

  useEffect(() => {
    const filterUsers = (query) => {
      const lowercasedQuery = query.toLowerCase();
      const filtered = users.filter(user => {
        return (
          user.name.toLowerCase().includes(lowercasedQuery) ||
          user.email.toLowerCase().includes(lowercasedQuery) ||
          getCustomerName(user.customer).toLowerCase().includes(lowercasedQuery) || // Filter by customer name
          user.upfitter.toLowerCase().includes(lowercasedQuery) ||
          user.role.toLowerCase().includes(lowercasedQuery)
        );
      });

      setFilteredUsers(filtered);
      setTotalRows(filtered.length);
      setCurrentPage(1); // Reset to the first page when filtering changes
    };

    filterUsers(searchQuery);
  }, [users, searchQuery, customers]); // Add customers to dependency array

  const handleUserAdded = async () => {
    const data = await getData('users');
    setUsers(data);
    setShowAddModal(false); // Close the modal after adding the user
  };

  const handleUserUpdated = async (updatedUser) => {
    const data = await getData('users');
    setUsers(data);
    setShowEditModal(false); // Close the modal after updating the user
  };

  const handleUserDeleted = async () => {
    await deleteData('users', userToDelete.id);
    const data = await getData('users');
    setUsers(data);
    setShowDeleteModal(false); // Close the modal after deleting the user
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(Number(event.target.value));
    setCurrentPage(1);
  };

  const handleSort = (column) => {
    let direction = 'asc';
    if (sortColumn === column && sortDirection === 'asc') {
      direction = 'desc';
    }
    setSortColumn(column);
    setSortDirection(direction);
  };

  useEffect(() => {
    const sortData = (data, column, direction) => {
      const sortedData = [...data].sort((a, b) => {
        if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
        if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
        return 0;
      });
      return sortedData;
    };

    setFilteredUsers(sortData(filteredUsers, sortColumn, sortDirection));
  }, [sortColumn, sortDirection]);

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredUsers.slice(indexOfFirstRow, indexOfLastRow);

  const renderPaginationItems = () => {
    const pageNumbers = [];
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxPagesToShow = 5;
    let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
    let endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

    if (endPage - startPage + 1 < maxPagesToShow) {
      startPage = Math.max(endPage - maxPagesToShow + 1, 1);
    }

    pageNumbers.push(
      <Pagination.First key="first" onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
    );
    pageNumbers.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
    );

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item key={i} active={i === currentPage} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }

    pageNumbers.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
    );
    pageNumbers.push(
      <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} disabled={currentPage === totalPages} />
    );

    return pageNumbers;
  };

  return (
    <>
      <InputGroup className="mb-2">
        <InputGroup.Text id="search-addon"><Search /></InputGroup.Text>
        <FormControl
          placeholder="Search users..."
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </InputGroup>
      <Table hover className="mt-3">
        <thead>
          <tr>
            <th>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-top">Add User</Tooltip>}
              >
                <Button
                  onClick={() => setShowAddModal(true)}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  className="icon-hover me-3"
                  variant="link"
                >
                  {isHovered ? <PlusCircleFill size={20} /> : <PlusCircle size={20} />}
                </Button>
              </OverlayTrigger>
            </th>
            <th onClick={() => handleSort('name')}>
              <div className="d-flex justify-content-between align-items-center">
                <span>Name</span>
                <ArrowDownUp />
              </div>
            </th>
            <th onClick={() => handleSort('email')}>
              <div className="d-flex justify-content-between align-items-center">
                <span>Email</span>
                <ArrowDownUp />
              </div>
            </th>
            <th onClick={() => handleSort('customer')}>
              <div className="d-flex justify-content-between align-items-center">
                <span>Customer</span>
                <ArrowDownUp />
              </div>
            </th>
            <th onClick={() => handleSort('upfitter')}>
              <div className="d-flex justify-content-between align-items-center">
                <span>Upfitter</span>
                <ArrowDownUp />
              </div>
            </th>
            <th onClick={() => handleSort('role')}>
              <div className="d-flex justify-content-between align-items-center">
                <span>Role</span>
                <ArrowDownUp />
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map(user => (
            <tr key={user.id}>
              <td></td>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{getCustomerNames(user.customers)}</td> {/* Display list of customer names */}
              <td>{getUpfitterName(user.upfitter)}</td> {/* Display upfitter name */}
              <td>{user.role}</td>
              <td>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Detail</Tooltip>}>
                  <Button
                    onClick={() => { setUserToShow(user); setShowDetailModal(true); }}
                    variant="white"
                    style={{ padding: '2px 4px' }}
                  >
                    <InfoCircle color="blue" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Edit</Tooltip>}>
                  <Button
                    onClick={() => { setUserToEdit(user); setShowEditModal(true); }}
                    variant="white"
                    style={{ padding: '2px 4px' }}
                  >
                    <Wrench color="black" />
                  </Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip-top">Delete</Tooltip>}>
                  <Button
                    onClick={() => { setUserToDelete(user); setShowDeleteModal(true); }}
                    variant="white"
                    style={{ padding: '2px 4px' }}
                  >
                    <Trash color="red" />
                  </Button>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>

      </Table>

      <div className="d-flex justify-content-between align-items-center my-3 mb-5" style={{ flexWrap: 'wrap' }}>
        <div>
          Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, totalRows)} of {totalRows} rows
        </div>
        <div className="d-flex align-items-center">
          <span className="me-2">Rows per page:</span>
          <Form.Select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: 'auto' }}>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </Form.Select>
        </div>
        <Pagination className="mb-0">
          {renderPaginationItems()}
        </Pagination>
      </div>

      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserForm onClose={handleUserAdded} />
        </Modal.Body>
      </Modal>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <UserForm user={userToEdit} onClose={handleUserUpdated} />
        </Modal.Body>
      </Modal>

      <Modal show={showDetailModal} onHide={() => setShowDetailModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userToShow && (
            <div style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
              <div><strong>Name:</strong> {userToShow.name}</div>
              <div><strong>Email:</strong> {userToShow.email}</div>
              <div><strong>Customer:</strong> {getCustomerName(userToShow.customer)}</div> {/* Display customer name */}
              <div><strong>Upfitter:</strong> {getUpfitterName(userToShow.upfitter)}</div> {/* Display upfitter name */}
              <div><strong>Role:</strong> {userToShow.role}</div>
            </div>
          )}
        </Modal.Body>

      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this user?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleUserDeleted}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UserList;
