import React, { useRef, useState, useEffect } from 'react';
import { useFirestore } from '../contexts/FirestoreContext';
import { Form, Button, Col, Row, FloatingLabel, Spinner, ButtonGroup, Dropdown } from 'react-bootstrap';
import { Timestamp } from 'firebase/firestore';
//import { createSalesOrderFolder, moveSalesOrderFolder } from '../utils/teamsUtils'; // Import the utility function

function SalesOrderForm({ salesOrder, onSalesOrderUpdated, onSalesOrderAdded, onModalClose }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [dans, setDans] = useState([]);
  const [numDans, setNumDans] = useState(0); // State for the number of DANs
  const [fordOrders, setFordOrders] = useState([]);
  const [numFordOrders, setNumFordOrders] = useState(0); // State for the number of Ford Orders
  const { addData, updateData, getData, getDocument, queryData } = useFirestore();
  const [buttonsDisabled, setButtonsDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false); // State to track form submission
  const [submitMessage, setSubmitMessage] = useState("");
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(salesOrder ? salesOrder.strCustNbr : ''); // Use state for the selected customer
  const [newFordOrder, setNewFordOrder] = useState(''); // State for manually adding a Ford Order
  const [newDan, setNewDan] = useState(''); // State for manually adding a DAN number
  const [searchCustTerm, setSearchCustTerm] = useState('');
  const [searchIdTerm, setSearchIdTerm] = useState(''); // State to store search input
  const [filteredCustomers, setFilteredCustomers] = useState([]); // Filtered customer list
  const [salesIds, setSalesIds] = useState([]);
  const [filteredSalesIds, setFilteredSalesIds] = useState([]);
  const [selectedSalesId, setSelectedSalesId] = useState(salesOrder ? salesOrder.strSalesId : '');


  const salesOrdNbrRef = useRef();
  const salesOfficeRef = useRef();
  const customerRef = useRef();
  const salesOrdStatusRef = useRef();
  const keysReqRef = useRef();
  const salesOrdMemoRef = useRef();
  const poVerifiedRef = useRef();
  const poVerifiedDateRef = useRef();
  const salesIdRef = useRef();
  const poNbrRef = useRef();

  useEffect(() => {
    const fetchCustomers = async () => {
      const customerData = await getData('customers');
      // Sort customers alphabetically by 'strCustName'
      const sortedCustomers = customerData.sort((a, b) => a.strCustName.localeCompare(b.strCustName));
      setCustomers(sortedCustomers);
    };
    fetchCustomers();
  }, [getData]);

  // Fetch the Sales Ids from Firestore
  useEffect(() => {
    const fetchSalesIds = async () => {
      const salesIdsData = await getData('salesIds');
      console.log("Sales Ids: ", salesIdsData)
      const sortedSalesIds = salesIdsData.sort((a, b) => a.strSalesIdName.localeCompare(b.strSalesIdName));
      console.log("Sorted Sales Ids: ", sortedSalesIds)
      setSalesIds(sortedSalesIds);
    };
    fetchSalesIds();
  }, [getData]);

  // Filter the sales Id list based on search term
  useEffect(() => {
    if (searchIdTerm === '') {
      setFilteredSalesIds(salesIds);
    } else {
      setFilteredSalesIds(
        salesIds.filter((Id) =>
          Id.strSalesIdName.toLowerCase().includes(searchIdTerm.toLowerCase())
        )
      );
    }
  }, [searchIdTerm, salesIds]);

  useEffect(() => {
    console.log("Filtered Sales Ids: ", filteredSalesIds)
  }, [filteredSalesIds]);



  useEffect(() => {
    const initializeSalesOrderNumber = async () => {
      try {
        const newSalesOrderNumber = await generateSalesOrderNumber();
        if (salesOrdNbrRef.current) salesOrdNbrRef.current.value = newSalesOrderNumber;
      } catch (error) {
        console.error(error);
      }
    };

    if (salesOrder) {
      if (salesOrdNbrRef.current) salesOrdNbrRef.current.value = salesOrder.strSalesOrdNbr;
      if (salesOfficeRef.current) salesOfficeRef.current.value = salesOrder.strSalesOffice;
      if (customerRef.current) customerRef.current.value = salesOrder.strCustNbr;
      if (salesOrdStatusRef.current) salesOrdStatusRef.current.value = salesOrder.strSalesOrdStatus;
      if (keysReqRef.current) keysReqRef.current.value = salesOrder.nKeysReq;
      if (salesOrdMemoRef.current) salesOrdMemoRef.current.value = salesOrder.lstrSalesOrdMemo;
      if (poVerifiedRef.current) poVerifiedRef.current.checked = salesOrder.bPOVerified;
      if (poVerifiedDateRef.current) poVerifiedDateRef.current.value = salesOrder.dtPOVerifiedDate ? convertTimestampToDate(salesOrder.dtPOVerifiedDate) : '';
      if (salesIdRef.current) salesIdRef.current.value = salesOrder.strSalesId;
      if (poNbrRef.current) poNbrRef.current.value = salesOrder.strPONbr;
      if (salesOrder.arrDans) setDans(salesOrder.arrDans); // Load existing DANs if present
      if (salesOrder.arrFordOrders) setFordOrders(salesOrder.arrFordOrders); // Load existing Ford Orders if present
    } else {
      initializeSalesOrderNumber();
    }


    return () => {
      if (!formSubmitted && !salesOrder) {
        cleanupSalesOrderNumber();
      }
    };
  }, [salesOrder]);

  // Filter the customer list based on the search term
  useEffect(() => {
    if (searchCustTerm === '') {
      setFilteredCustomers(customers); // Show all customers if search is empty
    } else {
      setFilteredCustomers(
        customers.filter((customer) =>
          customer.strCustName.toLowerCase().includes(searchCustTerm.toLowerCase())
        )
      );
    }
  }, [searchCustTerm, customers]);

  // Handle customer selection from the dropdown
  const handleCustomerSelect = (eventKey) => {
    setSelectedCustomer(eventKey);
  };

  // Handle sales Id selection from the dropdown
  const handleSalesIdSelect = (eventKey) => {
    setSelectedSalesId(eventKey);
  };

  const handleCustomerChange = (e) => {
    setSelectedCustomer(e.target.value);
  };

  const generateSalesOrderNumber = async () => {
    try {
      const today = new Date();
      const yy = today.getFullYear().toString().slice(-2);
      const mm = (today.getMonth() + 1).toString().padStart(2, '0');
      const dd = today.getDate().toString().padStart(2, '0');
      const todayString = `${yy}${mm}${dd}`;

      let salesOrdersAppendsData;

      try {
        salesOrdersAppendsData = await getDocument('dataCollection', 'salesOrdersAppends');
      } catch (error) {
        salesOrdersAppendsData = { date: '', numbers: [] };
      }

      if (salesOrdersAppendsData.date !== todayString) {
        salesOrdersAppendsData.date = todayString;
        salesOrdersAppendsData.numbers = [];
      }

      let newNumber;
      if (salesOrdersAppendsData.numbers.length === 0) {
        newNumber = '00';
      } else {
        const lastNumber = salesOrdersAppendsData.numbers[salesOrdersAppendsData.numbers.length - 1];
        if (lastNumber === '99') {
          console.error("All possible sales order numbers for today have been used.");
          setError('All possible sales order numbers for today have been used.');
          setButtonsDisabled(true);
          return null;
        }
        newNumber = (parseInt(lastNumber, 10) + 1).toString().padStart(2, '0');
      }

      salesOrdersAppendsData.numbers.push(newNumber);

      await updateData('dataCollection', 'salesOrdersAppends', salesOrdersAppendsData);

      const generatedSalesOrderNumber = `${todayString}-${newNumber}`;

      return generatedSalesOrderNumber;
    } catch (error) {
      console.error("Error generating Sales Order Number:", error);
      throw new Error("Failed to generate Sales Order Number");
    }
  };

  const cleanupSalesOrderNumber = async () => {
    try {
      let newNumber = null
      if (salesOrdNbrRef.current) newNumber = salesOrdNbrRef.current.value.split('-')[1];
      if (newNumber !== null) {
        let salesOrdersAppendsData = await getDocument('dataCollection', 'salesOrdersAppends');
        salesOrdersAppendsData.numbers = salesOrdersAppendsData.numbers.filter(number => number !== newNumber);
        await updateData('dataCollection', 'salesOrdersAppends', salesOrdersAppendsData);
      }
    } catch (error) {
      console.error("Error cleaning up Sales Order Number:", error);
    }
  };

  const convertTimestampToDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toISOString().split('T')[0];
    }
    return timestamp;
  };

  const requestDans = async () => {
    try {
      const lastDanDoc = await getDocument('dataCollection', 'lastDan'); // Fetch the lastDan document correctly
      let newAlphaNumber = lastDanDoc.alphaNumber;
      let newDansArray = [];

      // Generate the required number of new DANs
      for (let i = 0; i < numDans; i++) {
        newAlphaNumber = incrementDans(newAlphaNumber); // Reuse the increment function
        newDansArray.push(newAlphaNumber);
      }

      // Append the new DANs to the existing array
      setDans(prevDans => [...prevDans, ...newDansArray]);

      // Update the lastDan document with the final newAlphaNumber
      await updateData('dataCollection', 'lastDan', {
        alphaNumber: newAlphaNumber,
        year: new Date().getFullYear() // Optionally update the year if needed
      });
    } catch (error) {
      setError('Failed to request DANs');
      console.error("Error requesting DANs:", error);
    }
  };


  
  const requestFordOrders = async () => {
    try {
      const lastFordOrderDoc = await getDocument('dataCollection', 'lastFOrder'); // Fetch the lastFOrder document correctly
      let newAlphaNumber = lastFordOrderDoc.alphaNumber;
      let newFordOrdersArray = [];

      for (let i = 0; i < numFordOrders; i++) {
        newAlphaNumber = incrementAlphaNumeric(newAlphaNumber);
        newFordOrdersArray.push(newAlphaNumber);
      }

      setFordOrders(prevFordOrders => [...prevFordOrders, ...newFordOrdersArray]); // Append new Ford Orders to the existing array

      // Update the lastFOrder document with the final newAlphaNumber
      await updateData('dataCollection', 'lastFOrder', {
        alphaNumber: newAlphaNumber,
        year: new Date().getFullYear() // Optionally update the year if needed
      });
    } catch (error) {
      setError('Failed to request Ford Orders');
      console.error("Error requesting Ford Orders:", error);
    }
  };


  const incrementDans = (alphaNum) => {
    const chars = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ'; // Base-36 excluding I and O
    let newAlphaNum = '';
    let carry = true;

    // Loop through each character from right to left
    for (let i = alphaNum.length - 1; i >= 0; i--) {
      const char = alphaNum[i];
      let newIndex = chars.indexOf(char) + (carry ? 1 : 0);

      if (newIndex >= chars.length) {
        newIndex = 0; // Wrap around
        carry = true; // Carry over to the next character
      } else {
        carry = false; // No carry needed
      }

      newAlphaNum = chars[newIndex] + newAlphaNum;
    }

    // If the left-most digit rolled over, prepend '0' to maintain the length
    if (carry) {
      newAlphaNum = '0' + newAlphaNum;
    }

    // Skip invalid numbers containing I or O
    if (/I|O/.test(newAlphaNum)) {
      return incrementDans(newAlphaNum); // Recurse if invalid
    }

    // Check if the new value exceeds "ZZZZ" and reset to "1000" if necessary
    if (newAlphaNum.length > 4 || newAlphaNum === 'ZZZZ') {
      return '1000';
    }

    return newAlphaNum;
  };

  const incrementAlphaNumeric = (alphaNum) => {
    const chars = '0123456789ABCDEFGHJKLMNPQRSTUVWXYZ'; // Base-36 excluding I and O
    let newAlphaNum = '';
    let carry = true;

    // Loop through each character from right to left, except for the last character
    for (let i = alphaNum.length - 2; i >= 0; i--) {
      const char = alphaNum[i];
      let newIndex = chars.indexOf(char) + (carry ? 1 : 0);

      if (newIndex >= chars.length) {
        newIndex = 0; // Wrap around
        carry = true; // Carry over to the next character
      } else {
        carry = false; // No carry needed
      }

      newAlphaNum = chars[newIndex] + newAlphaNum;
    }

    // Ensure the last character is always a number (0-9)
    const lastDigit = parseInt(alphaNum.slice(-1), 10);
    let newLastDigit = (lastDigit + 1) % 10; // Increment the last digit, wrap around from 9 to 0

    // Combine the new alpha part with the new last digit
    newAlphaNum = newAlphaNum + newLastDigit.toString();

    // If the sequence reaches BZZZ (or the last allowed combination), reset it to B000
    if (newAlphaNum === 'C000') {
      newAlphaNum = 'B000';
    }

    return newAlphaNum;
  };


  async function handleSubmit(e) {
    e.preventDefault();
    setError('');
    setLoading(true);
    setFormSubmitted(true); // Mark the form as submitted

    try {
      const newSalesOrder = {
        strSalesOrdNbr: salesOrdNbrRef.current ? salesOrdNbrRef.current.value : '', // Add a fallback if ref is undefined
        strSalesOffice: salesOfficeRef.current ? salesOfficeRef.current.value : '',
        strCustNbr: selectedCustomer || '', // Use the selectedCustomer state
        strSalesOrdStatus: salesOrdStatusRef.current ? salesOrdStatusRef.current.value : '',
        nKeysReq: parseInt(keysReqRef.current ? keysReqRef.current.value : 0),
        lstrSalesOrdMemo: salesOrdMemoRef.current ? salesOrdMemoRef.current.value : '',
        dtPOVerifiedDate: poVerifiedDateRef.current && poVerifiedDateRef.current.value ? new Date(poVerifiedDateRef.current.value) : null,
        strSalesId: selectedSalesId || '', // Use the selectedSalesId state
        strPONbr: poNbrRef.current ? poNbrRef.current.value : '',
        arrDans: dans || [], // Add DANs array to the Sales Order
        arrFordOrders: fordOrders || [] // Add Ford Orders array to the Sales Order
      };

      if (salesOrder) {
        await updateData('salesOrders', salesOrder.id, newSalesOrder);
        onSalesOrderUpdated(newSalesOrder);
      } else {
        await addData('salesOrders', newSalesOrder);
        if (onSalesOrderAdded) {
          onSalesOrderAdded(newSalesOrder);
        }
      }

      if (dans.length !== 0) {
        const lastDanDoc = {
          alphaNumber: dans[dans.length - 1],
          year: new Date().getFullYear()
        };
        await updateData('dataCollection', 'lastDan', lastDanDoc);
      }

      if (fordOrders.length !== 0) {
        const lastFOrderDoc = {
          alphaNumber: fordOrders[fordOrders.length - 1],
          year: new Date().getFullYear()
        };
        await updateData('dataCollection', 'lastFOrder', lastFOrderDoc);
      }
    } catch (err) {
      setError('Failed to save sales order');
      console.error("Error updating/adding sales order:", err);
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className="mt-4" style={{ width: '100%', margin: '0 auto' }}>
      {error && <div className="alert alert-danger">{error}</div>}
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="salesOrdNbr">
              <FloatingLabel controlId="salesOrdNbr" label="Sales Order Number">
                <Form.Control type="text" ref={salesOrdNbrRef} disabled />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="customer">
              <div className="d-flex w-100">
                <div className="flex-grow-1"> {/* This will allow the FloatingLabel to take most of the space */}
                  <FloatingLabel controlId="customer" label="Customer" className="w-100">
                    <Form.Control
                      type="text"
                      value={selectedCustomer ? customers.find((customer) => customer.strCustNbr === selectedCustomer)?.strCustName : 'Select Customer'}
                      disabled
                    />
                  </FloatingLabel>
                </div>
                <Dropdown as={ButtonGroup} onSelect={handleCustomerSelect}> {/* Dropdown next to FloatingLabel */}
                  <Dropdown.Toggle split variant="light" className="custom-split-toggle" /> {/* Dropdown will take minimal space */}
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <input
                      type="text"
                      placeholder="Search..."
                      onChange={(e) => setSearchCustTerm(e.target.value)}
                      style={{ margin: "8px 10px", width: "calc(100% - 20px)", borderRadius: "5px" }}
                    />
                    <Dropdown.Item disabled>Select Customer</Dropdown.Item> {/* Add this placeholder option */}
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers.map((customer) => (
                        <Dropdown.Item key={customer.strCustNbr} eventKey={customer.strCustNbr}>
                          {customer.strCustName}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No matching customers</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Form.Group>

          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="keysReq">
              <FloatingLabel controlId="keysReq" label="Number of Keys Required">
                <Form.Control type="number" ref={keysReqRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="salesOrdStatus">
              <FloatingLabel controlId="salesOrdStatus" label="Sales Order Status">
                <Form.Control as="select" ref={salesOrdStatusRef}>
                  <option value="Active">Active</option>
                  <option value="Complete">Complete</option>
                  <option value="Void">Void</option>
                </Form.Control>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="poNbr">
              <FloatingLabel controlId="poNbr" label="PO Number">
                <Form.Control type="text" ref={poNbrRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="poVerifiedDate">
              <FloatingLabel controlId="poVerifiedDate" label="PO Verified Date">
                <Form.Control type="date" ref={poVerifiedDateRef} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="salesOffice">
              <FloatingLabel controlId="salesOffice" label="Sales Office">
                <Form.Control as="select" ref={salesOfficeRef}>
                  <option value="East">East</option>
                  <option value="West">West</option>
                </Form.Control>
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="salesId">
              <div className="d-flex w-100">
                <div className="flex-grow-1">
                  <FloatingLabel controlId="salesId" label="Sales ID" className="w-100">
                    <Form.Control
                      type="text"
                      value={salesIds.find((Id) => Id.strSalesIdNbr === selectedSalesId)?.strSalesIdName || ''}
                      disabled
                    />
                  </FloatingLabel>
                </div>
                <Dropdown as={ButtonGroup} onSelect={handleSalesIdSelect}>
                  <Dropdown.Toggle split variant="light" className="custom-split-toggle" />
                  <Dropdown.Menu className="custom-dropdown-menu">
                    <input
                      type="text"
                      placeholder="Search..."
                      onChange={(e) => setSearchIdTerm(e.target.value)}
                      style={{ margin: "8px 10px", width: "calc(100% - 20px)", borderRadius: "5px" }}
                    />
                    {filteredSalesIds.length > 0 ? (
                      filteredSalesIds.map((Id) => (
                        <Dropdown.Item key={Id.strSalesIdNbr} eventKey={Id.strSalesIdNbr}>
                          {Id.strSalesIdName}
                        </Dropdown.Item>
                      ))
                    ) : (
                      <Dropdown.Item disabled>No matching Sales IDs</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Group className="mb-3" controlId="salesOrdMemo">
              <FloatingLabel controlId="salesOrdMemo" label="Sales Order Memo">
                <Form.Control as="textarea" ref={salesOrdMemoRef} style={{ height: '100px' }} />
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="newFordOrder">
              <FloatingLabel controlId="newFordOrder" label="Add Ford Orders (comma separated)">
                <Form.Control
                  type="text"
                  value={newFordOrder}
                  onChange={(e) => setNewFordOrder(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Button
              variant="primary"
              onClick={() => {
                if (newFordOrder.trim()) {
                  const newFordOrdersArray = newFordOrder.split(',').map(order => order.trim()).filter(order => order);
                  setFordOrders(prevFordOrders => [...prevFordOrders, ...newFordOrdersArray]);
                  setNewFordOrder(''); // Clear the input field
                }
              }}
              disabled={loading || buttonsDisabled || !newFordOrder.trim()}
            >
              Add Ford Orders
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="numFordOrders">
              <FloatingLabel controlId="numFordOrders" label="Number of Ford Orders">
                <Form.Control type="number" value={numFordOrders} onChange={(e) => setNumFordOrders(e.target.value)} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col>
            <Button variant="primary" onClick={requestFordOrders} disabled={loading || buttonsDisabled}>
              Request Ford Orders
            </Button>
          </Col>
          <Col>
            {fordOrders.length > 0 && (
              <Form.Group className="mt-3" controlId="fordOrders">
                  {fordOrders.map((order, index) => (
                    <>{order}, </>
                  ))}
              </Form.Group>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="newDan">
              <FloatingLabel controlId="newDan" label="Add DANs (comma separated)">
                <Form.Control
                  type="text"
                  value={newDan}
                  onChange={(e) => setNewDan(e.target.value)}
                />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Button
              variant="primary"
              onClick={() => {
                if (newDan.trim()) {
                  const newDansArray = newDan.split(',').map(dan => dan.trim()).filter(dan => dan);
                  setDans(prevDans => [...prevDans, ...newDansArray]);
                  setNewDan(''); // Clear the input field
                }
              }}
              disabled={loading || buttonsDisabled || !newDan.trim()}
            >
              Add DANs
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <Form.Group className="mb-3" controlId="numDans">
              <FloatingLabel controlId="numDans" label="Number of DANs">
                <Form.Control type="number" value={numDans} onChange={(e) => setNumDans(e.target.value)} />
              </FloatingLabel>
            </Form.Group>
          </Col>
          <Col>
            <Button variant="primary" onClick={requestDans} disabled={loading || buttonsDisabled}>
              Request DANs
            </Button>
          </Col>
          <Col>
            {dans.length > 0 && (
              <Form.Group className="mt-3" controlId="dans">
                {dans.map((dan, index) => (
                  <span key={index}>{dan}{index < dans.length - 1 ? ', ' : ''}</span>
                ))}
              </Form.Group>
            )}
          </Col>
        </Row>
        <Button
          variant="primary"
          type="submit"
          disabled={loading || buttonsDisabled || !selectedCustomer} // Disable if no customer is selected
          className="mt-3"
        >
          {salesOrder ? 'Update Sales Order' : 'Add Sales Order'}
        </Button>
      </Form>
      {teamsLoading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <div>{submitMessage}</div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default SalesOrderForm;
